import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Radio,
  ListItemText,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DeleteIcon from '@material-ui/icons/Delete';
import PaymentIcon from 'react-payment-icons-inline';

const SINGLE_PAYMENT_PAGE = 'Single Payment';
const NEW_CARD = 'NEW_CARD';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    backgroundColor: '#fafafc',
    padding: theme.spacing(1.5, 3),
    borderRadius: 16,
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.primary.main,
    verticalAlign: 'middle',
    marginRight: theme.spacing(2),
  },
  label: {
    fontWeight: 'bold',
  },
  switch: {
    marginLeft: 'auto',
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 1.5),
    boxSizing: 'border-box',
  },
  listItem: {
    borderBottom: '1px solid #efefef',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  listItemIcon: {
    minWidth: '0px',
    marginRight: '9px',
  },
  listItemIconNew: {
    minWidth: '0px',
    marginRight: '15px',
  },
  radio: {
    padding: '0px',
  },
  paymentIcon: {
    padding: '5px',
    width: '30px',
    border: '1px solid #efefef',
    borderRadius: '5px',
  },
  listItemText: {
    paddingLeft: '20px',
  },
}));

const SavedCards = ({
  onChange,
  removing,
  handleRemoveCard,
  pageType,
  cards,
  defaultCard,
  ...props
}: any) => {
  const classes = useStyles();

  const [selectedCard, setSelectedCard] = useState();

  useEffect(() => {
    setSelectedCard(defaultCard);
    onChange(defaultCard);
  }, [defaultCard]); //eslint-disable-line

  useEffect(() => {
    if (!cards.some((card) => card.cardId === selectedCard)) {
      setSelectedCard(defaultCard);
    }
  }, [cards, defaultCard, selectedCard]);

  const handleToggle = (value) => () => {
    setSelectedCard(value);
    onChange(value);
  };

  const formatCardNumber = (value) => {
    return '●●●● ●●●● ●●●● ' + value;
  };

  return (
    <>
      <div className={classes.header}>
        <CreditCardIcon className={classNames(classes.icon)} />
        <Typography className={classNames(classes.label)}>SAVED CARDS</Typography>
      </div>
      <List className={classNames(classes.list)}>
        {cards.map((card) => (
          <ListItem
            className={classNames(classes.listItem)}
            key={card.cardId}
            role={undefined}
            dense
            button
            onClick={handleToggle(card.cardId)}>
            <ListItemIcon className={classNames(classes.listItemIcon)}>
              <Radio
                className={classNames(classes.radio)}
                color="primary"
                checked={selectedCard === card.cardId}
                value={card.cardId}
              />
            </ListItemIcon>
            <PaymentIcon icon={card.brand} className={classNames(classes.paymentIcon)} />
            <ListItemText
              className={classNames(classes.listItemText)}
              primary={formatCardNumber(card.lastDigits)}
            />
            <IconButton edge="end" onClick={(event) => handleRemoveCard(card.cardId, event)}>
              {removing === card.cardId ? <CircularProgress /> : <DeleteIcon />}
            </IconButton>
          </ListItem>
        ))}
        {pageType === SINGLE_PAYMENT_PAGE && (
          <ListItem
            className={classNames(classes.listItem)}
            key={NEW_CARD}
            role={undefined}
            dense
            button
            onClick={handleToggle(NEW_CARD)}>
            <ListItemIcon className={classNames(classes.listItemIconNew)}>
              <Radio
                className={classNames(classes.radio)}
                color="primary"
                checked={selectedCard === NEW_CARD}
                value={NEW_CARD}
              />
            </ListItemIcon>
            <Typography component="div">Use new card</Typography>
          </ListItem>
        )}
      </List>
    </>
  );
};

export default SavedCards;
